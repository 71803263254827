<template>
  <div class="banner-middle">
    <ModalVideo
      :link-video="linkVideo"
      :show-modal="showModal"
      @close-modal="closeModal"
    />

    <div class="banner-middle__content">
      <div class="banner-middle__content__titles">
        <h2>{{ $t("announcing.title") }}</h2>
        <h3>{{ $t("announcing.subtitle") }}</h3>
      </div>
      <div class="banner-middle__content__video">
        <img src="@/assets/img/GameJam/Announcing.png" alt="map" />
        <img
              src="@/assets/svg/playVideo.svg"
              alt="play the video"
              class="play-video"
              @click="openVideo('https://www.youtube.com/embed/0Jquhzr4d4k')"
        >
      </div>
    </div>
  </div>
</template>

<script>
import ModalVideo from "@/components/ModalVideo"

export default {
  name: "Announcing",
  components: {
    ModalVideo
  },
  data() {
    return {
      showModal: false,
      linkVideo: ""
    }
  },
  methods: {
    openVideo(linkVideo) {
      this.linkVideo = linkVideo;
      this.showModal = true;
    },
    track() {
      var axel = Math.random() + "";
      var a = axel * 10000000000000;
      const iframe = document.createElement('iframe');
      iframe.src = 'https://10802094.fls.doubleclick.net/activityi;src=10802094;type=invmedia;cat=2021-000;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=' + a + '?';
      iframe.style.display = "none";
      iframe.style.width = "1px";
      iframe.style.height = "1px";
      document.body.appendChild(iframe);
    },
    closeModal() {
      this.showModal = false;
    }
  }
}
</script>

<style lang="scss" scoped>
  .banner-middle {
    background-color: $grey-12;
    @include flex(column, center, center);
    padding: 100px 0;
    position: relative;
    h2, h3 {
      font-size: 50px;
      font-weight: 1000;
      line-height: 50px;
      margin: 0;
      text-align: center;
    }
    &__content {
      margin-top: 120px;
      z-index: 1;
      width: 100%;
      max-width: 1335px;
      @include flex(column, center, center);
      &__titles {
        margin-bottom: 20px;
        h2 {
          color: $red-2;
          text-transform: uppercase;
        }
      }
      &__video {
        width: calc(55.5%);
        position: relative;
        img {
          width: 100%;
        }
        .play-video {
          width: 60px;
          height: 60px;
          position: absolute;
          top: calc(50% - 30px);
          left: calc(50% - 30px);
          cursor: pointer;
        }
      }
    }
  }
  @include breakpoint($max: 1500) {
    .banner-middle {
      &__content {
        min-width: 900px;
      }
    }
  }
  @include breakpoint($max: 1100) {
    .banner-middle {
      &__content {
        min-width: auto;
        margin-top: 100px;
        width: 86%;
        @include flex(column, center, center);
        &__titles {
          text-align: center;
          h2 {
            font-size: 35px;
            line-height: 40px;
          }
          h3 {
            font-size: 22px;
          }
        }
        &__video {
          width: 100%;
          margin-top: 40px;
          max-width: 500px;
        }
      }
    }
  }
  @include breakpoint($max: 500) {
    .banner-middle {
      h2 {
        font-size: 30px;
        line-height: 36px;
      }
    }
  }
</style>
