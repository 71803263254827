<template>
  <div class="landing">
    <HeaderLanding />
    <BannerTop />
    <Login />
    <BannerMiddleA />
    <Login />
    <Partners />
    <Footer />
  </div>
</template>

<script>
import HeaderLanding from "@/components/HeaderLanding.vue"
import BannerTop from "@/components/BannerTop.vue"
import Login from "@/components/Login.vue"
import BannerMiddleA from "@/components/BannerMiddleA.vue"
import Partners from "@/components/Partners.vue"
import Footer from "@/components/Footer.vue"

export default {
  name: 'Landing',
  components: {
    HeaderLanding,
    BannerTop,
    Login,
    BannerMiddleA,
    Partners,
    Footer
  }
}
</script>

<style lang="scss" scoped>
  .landing {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
</style>
