<template>
  <div
    class="login"
    :style="{ 'background-image': `url(${getImageBg()})` }"
  >
    <img src="@/assets/img/login-image.png" alt="image">
    <div class="login__bg" />
    <a
      :href="`https://www.sandbox.game/${$i18n.locale}/login/?r=Byvqzsd7h0mr58pEoWdCr~&name=TheWalkingDead`"
      target="_blank"
    >
      {{ $t("login.button") }}
    </a>
    <p>
      {{ $t("login.descriptionRegular1") }}
      <span>{{ $t("login.descriptionBold") }}</span>
      {{ $t("login.descriptionRegular2") }}
    </p>
  </div>
</template>

<script>
export default {
  name: "Login",
  methods: {
    getImageBg() {
      return require('../assets/img/scratches.jpeg');
    }
  }
}
</script>

<style lang="scss" scoped>
  .login {
    background: $red;
    padding: 30px 20px;
    background-blend-mode: overlay;
    @include flex(column, center, center);
    position: relative;
    &__bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      background-color: $red;
      z-index: 0;
    }
    img {
      position: absolute;
      z-index: 2;
      width: 185px;
      right: 450px;
      top: -65px;
    }
    a {
      background-color: $gray2;
      z-index: 1;
      color: white;
      border: none;
      font-weight: bold;
      text-transform: uppercase;
      padding: 15px 25px;
      border-radius: 60px;
      font-size: 17px;
      cursor: pointer;
      text-decoration: none;
      transition: 200ms;
      &:hover {
        background-color: white;
        color: $gray2;
      }
    }
    p {
      z-index: 1;
      font-size: 14px;
      margin: 0px;
      margin-top: 20px;
      span {
        font-weight: bold;
      }
    }
  }
  @include breakpoint($max: 1530) {
    .login {
      img {
        width: 150px;
        right: 340px;
        top: -50px;
      }
    }
  }
  @include breakpoint($max: 1280) {
    .login {
      img {
        width: 185px;
        right: 5%;
        top: -65px;
      }
    }
  }
  @include breakpoint($max: 800) {
    .login {
      text-align: center;
      img {
        display: none;
      }
    }
  }
</style>
