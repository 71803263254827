<template>
  <div class="footer">
    <div>
      <a
        v-for="(link, index) in links"
        :key="index"
        :href="link.link"
        target="_blank"
      >
        <img
          :src="require('../../assets/img/logosFooter/' + link.name + '.png')"
          :alt="link.name"
        >
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      links: [
        {
          link: "https://discord.com/invite/vAe4zvY",
          name: "discord"
        },
        {
          link: "https://t.me/sandboxgame",
          name: "telegram"
        },
        {
          link: "https://medium.com/sandbox-game",
          name: "medium"
        },
        {
          link: "https://twitter.com/thesandboxgame",
          name: "twitter"
        },
        {
          link: "https://www.instagram.com/thesandboxgame/",
          name: "instagram"
        },
        {
          link: "https://www.facebook.com/TheSandboxGame/",
          name: "facebook"
        },
        {
          link: "https://www.youtube.com/channel/UCzv1t7voB-bxMmXLysT4h0w",
          name: "youtube"
        },
        {
          link: "https://www.twitch.tv/thesandboxgame",
          name: "twitch"
        }
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
  .footer {
    @include flex(row, center, center);
    background-color: $grey-12;
    color: white;
    position: relative;
    height: 430px;
    z-index: 1;
    &::before {
      content: "";
      background-color: $grey-12;
      width: 110%;
      height: 180px;
      transform: rotateZ(1deg);
      top: -20px;
      left: -5%;
      position: absolute;
      z-index: -1;
    }
    a {
      cursor: pointer;
      margin: 0px 10px;
      img {
        width: 30px;
        height: 30px;
        object-fit: contain;
        &:last-of-type {
          width: 40px;
        }
      }
      &:first-of-type {
        margin-left: 0;
      }
    }
    h3 {
      margin: 0;
      margin-bottom: 15px;
      font-size: 25px;
    }
    &__logo-container {
      bottom: 20px;
      right: 30px;
      margin-top: 50px;
      @include flex(row, flex-end, space-between);
      width: 100%;
      a {
        img {
          height: 20px;
          width: 100px;
        }
      }
      p {
        font-size: 12px;
        max-width: 650px;
      }
    }
    &__images {
      position: absolute;
      top: -250px;
      left: 0;
      width: 100%;
      pointer-events: none;
      @include flex(row-reverse, flex-start, space-between);
      img {
        &:first-of-type {
          width: 150px;
          margin-left: 50px;
          margin-top: -100px;
        }
        &:last-of-type {
          width: 220px;
          margin-top: 0px;
          margin-right: 150px;
        }
      }
    }
  }
  @include breakpoint($max: 1450) {
    .footer {
      &__logo-container {
        @include flex(column, flex-start, space-between);
        a {
          position: absolute;
          right: 20px;
          bottom: 20px;
        }
      }
    }
  }
  @include breakpoint($max: 1100) {
    .footer {
      padding: 50px 10%;
      padding-bottom: 100px;
      a {
        margin: 0px 5px;
        img {
          width: 25px;
          height: 25px;
          margin: 7px 0px;
        }
      }
      &__logo-container {
        a {
          img {
            height: 20px;
            width: 100px;
          }
        }
      }
      &__images {
        display: none;
      }
    }
  }
</style>
