<template>
    <div class="main-header-landing">
        <nav class="navbar navbar-expand-lg items-container navbar-dark">
            <div class="logo-container">
                <a
                    href="https://www.sandbox.game/"
                    target="_blank"
                    class="navbar-brand ml-1">
                    <img
                        src="@/assets/svg/TSBLogo.svg"
                        alt="Logo Sandbox"
                        title="Sandbox"
                        class="logo" />
                </a>
            </div>
            <div>
                <div
                    v-click-outside="() => langSelectorDropdown = false"
                    class="lang-selector"
                    :class="{'opened': langSelectorDropdown}"
                    @click="langSelectorDropdown = !langSelectorDropdown">
                    <div class="flag-container">
                        <CountryFlag
                            class="d-flex align-items-center flag"
                            :country="countryFlag($i18n.locale)"
                            size="normal" />
                    </div>
                    <span class="actual-lang">
                        {{ $i18n.locale }}
                    </span>
                    <div
                        :class="{'hide': !langSelectorDropdown}"
                        :style="langSelectorDropdown ? `max-height: ${3 * langSelector.length}em;` : ''"
                        class="lang-selector-dropdown">
                        <div
                            v-for="(lang, k) in langSelector"
                            :key="k"
                            class="item"
                            :class="{'selected': $i18n.locale == lang}"
                            @click="changeLocale(lang)">
                            <div class="flag-container">
                                <CountryFlag
                                    class="d-flex align-items-center flag"
                                    :country="countryFlag(lang)"
                                    size="normal" />
                            </div>
                            {{ lang }}
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import CountryFlag from 'vue-country-flag-next'

export default {
    name: 'HeaderLanding',
    components: {
        CountryFlag
    },
    data () {
        return {
            langSelector: Object.keys(this.$i18n.messages),
            langSelectorDropdown: false,
            burgerState: false
        }
    },
    methods: {
        countryFlag (lang) {
            let flag = ''
            switch (lang) {
            case 'en':
                flag = 'gb'
                break
            default:
                flag = lang
                break
            }
            return flag
        },
        changeLocale (lang) {
            this.$router.replace({ name: this.$route.name, params: { lang: lang } }).catch(() => {})
        }
    }
}
</script>

<style lang="scss">
    .hideme { display: none !important; }
    $header-height: 80px;
    $lang-selector-height: 2.6em;
    $main-logo: url('../../assets/svg/VoxEditLogo.svg');
    $icon-chevron: url('../../assets/svg/ArrowDropdown.svg');

    .logo-container {
        @include flex(row, center, center);
        margin-left: 10px;
    }

    .main-header-landing {
        position: relative;
        width: 100%;
        top: 0;
        align-items: center;
        color: $grey-7;
        display: flex;
        font-weight: bold;
        height: $header-height;
        justify-content: space-between;
        z-index: 2;
    }

    .main-header-landing .flag-container {
        max-height: 16px;
        border: 1px solid;
        max-width: 22px;
        padding: 0;
        overflow: hidden;
        border-radius: 5px;
        margin: 1px 6px 0 0px;
        .flag {
            transform: scale(.38) !important;
            margin-top: -13px !important;
            margin-left: -20px !important;
        }
    }
    .main-header-landing .lang-selector {
        background: $grey-3;
        border-radius: .8em;
        height: $lang-selector-height;
        display: flex;
        align-items: center;
        justify-content: space-around;
        letter-spacing: 0;
        color: $white-1;
        opacity: 1;
        text-transform: uppercase;
        user-select: none;
        position: relative;
        width: 100px;
        margin-right: 20px;
        margin-top: 3px;
        padding: 9px;
        &:hover {
            cursor: pointer;
        }
        &::after {
            content: '';
            position: absolute;
            right: 10px;
            top: 16px;
            background: $icon-chevron;
            background-repeat: no-repeat;
            height: 1em;
            width: 1em;
            transition: all .2s ease;
        }
        &.opened::after {
            transform: rotateZ(-180deg);
            top: 9px;
        }
    }
    .main-header-landing .lang-selector-dropdown {
        position: absolute;
        top: 2.6em;
        left: 10px;
        border-bottom-left-radius: .8em;
        border-bottom-right-radius: .8em;
        z-index: 10;
        overflow: hidden;
        background: darken($grey-3, 5);
        transition: max-height .3s ease;
        &.hide {
            max-height: 0;
        }
        .item {
            min-width: 5.3em;
            min-height: 2.5em;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
                cursor: pointer;
                background: darken($grey-3, 7);
            }
        }
        .flag-container {
            margin: 1px 6px 0 0px;
        }
    }
    .main-header-landing .logo {
        height: 47px;
        margin-right: .5em;
        margin-left: 20px;
    }
    .main-header-landing .actual-lang {
        font-size: 15px;
        font-weight: 600;
        margin-right: 25px;
    }
    .main-header-landing .items-container {
        height: $header-height;
    }
    .main-header-landing .navbar-toggler {
        margin: 0 10px;
    }
    .main-header-landing .navbar-collapse, .main-header-landing .navbar-collapse > ul {
        height: $header-height;
    }
    .main-header-landing .navbar-brand {
        margin-right: 0;
    }
    .main-header-landing .navbar{
        margin: auto;
        width: 100%;
        max-width: 1470px;
        height: $header-height;
        padding-top: 10px;
        @include flex(row, flex-start, space-between);
    }

    @media screen and (max-width: 991px) {
        .main-header-landing .navbar-brand{
            padding: 0 !important;
        }
        .main-header-landing .navbar-collapse > ul{
            background: $grey-1;
            color: $grey-7;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            z-index: 9;
            height: auto;
        }
        .main-header-landing .navbar-collapse, .main-header-landing .collapsing {
            position: absolute !important;
            top: $header-height;
            height: auto;
            width: 100%;
        }
        .main-header-landing .flag-container {
            margin: 0;
        }
    }
</style>
