<template>
  <div class="banner-middle">
    <ModalVideo
      :link-video="linkVideo"
      :show-modal="showModal"
      @close-modal="closeModal"
    />

    <div class="banner-middle__second-part">
      <div class="banner-middle__second-part__titles">
        <h2>{{ $t("bannerMiddle.title3") }}</h2>
        <h3>{{ $t("bannerMiddle.subtitle2") }}</h3>
      </div>
      <div class="banner-middle__second-part__line-1">
        <div>
          <img src="@/assets/img/preview.png" alt="video">
          <img
            src="@/assets/svg/playVideo.svg"
            alt="play the video"
            class="play-video"
            @click="openVideo('https://www.youtube.com/embed/D2jZu3FlUwE')"
          >
        </div>
      </div>
      <div class="banner-middle__second-part__line-2">
        <div class="banner-middle__second-part__texts">
          <h4>{{ $t("bannerMiddle.subtitle3A") }}</h4>
          <p>{{ $t("bannerMiddle.paragraph2A") }}</p>
          <a
            :href="`https://sandbox.game/${$i18n.locale}/map/`"
            target="_blank"
            @click="track"
          >
            {{ $t("bannerMiddle.button1A") }}
          </a>
        </div>
        <div class="banner-middle__second-part__images">
          <img src="@/assets/img/map-2.png" alt="map" />
        </div>
      </div>
    </div>

    <div class="banner-middle__first-part">
      <div class="banner-middle__first-part__titles">
        <h2>{{ $t("bannerMiddle.title1") }}</h2>
        <h2>{{ $t("bannerMiddle.title2") }}</h2>
        <h3>{{ $t("bannerMiddle.subtitle1") }}</h3>
      </div>
      <div class="banner-middle__first-part__content">
        <div class="banner-middle__first-part__intro">
          <p>{{ $t("bannerMiddle.paragraph1") }}</p>
          <p>{{ $t("bannerMiddle.paragraph6") }}</p>
        </div>
        <div class="banner-middle__first-part__video">
          <img src="@/assets/img/iceland.png" alt="video">
          <img
            src="@/assets/svg/playVideo.svg"
            alt="play the video"
            class="play-video"
            @click="openVideo('https://www.youtube.com/embed/0Jquhzr4d4k')"
          >
        </div>
      </div>
    </div>

    <div class="banner-middle__cubes">
      <img src="@/assets/img/cubes/cube3.png" alt="cube">
      <img src="@/assets/img/cubes/cube4.png" alt="cube">
      <img src="@/assets/img/cubes/cube2.png" alt="cube">
      <img src="@/assets/img/cubes/cube1.png" alt="cube">
      <img src="@/assets/img/cubes/cube5.png" alt="cube">
    </div>
  </div>
</template>

<script>
import ModalVideo from "@/components/ModalVideo"

export default {
  name: "BannerMiddle",
  components: {
    ModalVideo
  },
  data() {
    return {
      showModal: false,
      linkVideo: ""
    }
  },
  methods: {
    openVideo(linkVideo) {
      this.linkVideo = linkVideo;
      this.showModal = true;
    },
    track() {
      var axel = Math.random() + "";
      var a = axel * 10000000000000;
      const iframe = document.createElement('iframe');
      iframe.src = 'https://10802094.fls.doubleclick.net/activityi;src=10802094;type=invmedia;cat=2021-000;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=' + a + '?';
      iframe.style.display = "none";
      iframe.style.width = "1px";
      iframe.style.height = "1px";
      document.body.appendChild(iframe);
    },
    closeModal() {
      this.showModal = false;
    }
  }
}
</script>

<style lang="scss" scoped>
  .banner-middle {
    background-color: $gray2;
    @include flex(column, center, center);
    padding: 100px 0;
    position: relative;
    h2, h3, p {
      margin: 0;
    }
    h4 {
      font-size: 28px;
      margin: 5px 0px;
    }
    p {
      line-height: 25px;
    }
    a {
      background-color: $red;
      padding: 10px 40px;
      font-weight: bold;
      border: none;
      border-radius: 20px;
      color: white;
      text-decoration: none;
      transition: 200ms;
      cursor: pointer;
      &:hover {
        background-color: $blue-5;
      }
    }
    &__first-part {
      margin-top: 120px;
      z-index: 1;
      width: 50%;
      min-width: 1000px;
      @include flex(column, flex-start, flex-start);
      &__titles {
        margin-bottom: 20px;
        h2 {
          font-size: 40px;
          font-weight: 1000;
          line-height: 50px;
          text-transform: uppercase;
        }
        h3 {
          font-size: 25px;
        }
      }
      &__content {
        width: 100%;
        margin-top: 20px;
        @include flex(row, flex-start, space-between);
      }
      &__intro {
        width: 300px;
        p {
          line-height: 23px;
          font-size: 15px;
          margin-bottom: 30px;
        }
      }
      &__video {
        width: calc(90% - 300px);
        position: relative;
        img {
          width: 100%;
        }
        .play-video {
          width: 60px;
          height: 60px;
          position: absolute;
          top: calc(50% - 30px);
          left: calc(50% - 30px);
          cursor: pointer;
        }
      }
    }

    &__second-part {
      z-index: 1;
      width: 1350px;
      @include flex(column, flex-start, flex-start);
      &__titles {
        margin-bottom: 20px;
        text-align: center;
        width: 100%;
        h2 {
          font-size: 40px;
          font-weight: 1000;
          line-height: 50px;
          text-transform: uppercase;
        }
        h3 {
          font-size: 25px;
        }
      }
      &__line-1 {
        width: 100%;
        margin-top: 20px;
        position: relative;
        div {
          position: relative;
          z-index: 2;
          width: 60%;
          img {
            width: 100%;
          }
          .play-video {
            width: 60px;
            height: 60px;
            position: absolute;
            top: calc(50% - 30px);
            left: calc(50% - 30px);
            cursor: pointer;
          }
          &::after {
            content: "";
            position: absolute;
            bottom: -15px;
            right: -20px;
            background-color: white;
            height: 220px;
            width: 50%;
            z-index: -1;
          }
        }
      }
      &__line-2 {
        width: 100%;
        margin-top: -5px;
        @include flex(row, flex-start, space-between);
        position: relative;
      }
      &__images {
        @include flex(row, flex-start, space-between);
        img {
          margin-top: -50px;
          width: 700px;
          z-index: 1;
          position: relative;
          @include flex(row, flex-end, flex-end);
        }
      }
      &__texts {
        width: 300px;
        margin-left: 200px;
        margin-top: 50px;
        p {
          width: 200px;
          margin-bottom: 40px;
          margin-top: 20px;
        }
      }
    }
    &__cubes {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      img {
        position: absolute;
        &:nth-of-type(1) {
          width: 100px;
          top: 40%;
          left: 12%;
        }
        &:nth-of-type(2) {
          width: 140px;
          top: 15%;
          right: 25%;
        }
        &:nth-of-type(3) {
          width: 160px;
          top: 63%;
          right: 15%;
        }
        &:nth-of-type(4) {
          width: 120px;
          top: 80%;
          left: 5%;
        }
        &:nth-of-type(5) {
          width: 80px;
          top: 90%;
          left: 35%;
        }
      }
    }
  }
  @include breakpoint($max: 1500) {
    .banner-middle {
      &__first-part {
        min-width: 900px;
      }
      &__second-part {
        width: 1000px;
        &__images {
          img {
            margin-top: -25px;
            width: 630px;
          }
        }
        &__texts {
          margin-left: 20px;
          margin-top: 30px;
        }
      }
      &__cubes {
        img {
          &:nth-of-type(1) {
            width: 100px;
            top: 25%;
            left: 5%;
          }
          &:nth-of-type(2) {
            width: 140px;
            top: 15%;
            right: 15%;
          }
          &:nth-of-type(3) {
            width: 140px;
            top: 60%;
            right: 15%;
          }
          &:nth-of-type(4) {
            width: 100px;
            top: 75%;
            left: 2%;
          }
          &:nth-of-type(5) {
            width: 70px;
            top: 90%;
            left: 35%;
          }
        }
      }
    }
  }
  @include breakpoint($max: 1100) {
    .banner-middle {
      &__first-part {
        min-width: auto;
        margin-top: 100px;
        width: 86%;
        @include flex(column, center, center);
        &__titles {
          text-align: center;
          h2 {
            font-size: 35px;
            line-height: 40px;
          }
          h3 {
            font-size: 22px;
          }
        }
        &__content {
          @include flex(column, center, center);
        }
        &__intro {
          width: auto;
          @include flex(column, center, center);
          text-align: center;
        }
        &__video {
          width: 100%;
          margin-top: 40px;
          max-width: 500px;
        }
      }

      &__second-part {
        width: 86%;
        &__line-1 {
          div {
            width: 100%;
            &::after {
              left: 5%;
              width: 90%;
              bottom: -5px;
              right: auto;
              height: 40px;
            }
          }
        }
        &__line-2 {
          @include flex(column, center, center);
        }
        &__images {
          @include flex(row, center, center);
          position: relative;
          width: 100%;
          img {
            margin-top: 50px;
            width: 100%;
          }
        }
        &__texts {
          width: auto;
          text-align: center;
          margin-left: 0px;
          margin-top: 60px;
          p {
            width: 90%;
            margin: 20px auto;
            margin-bottom: 40px;
          }
        }
      }
      &__cubes {
        img {
          &:nth-of-type(1) {
            width: 80px;
            top: 37%;
            left: 5%;
          }
          &:nth-of-type(2) {
            width: 120px;
            top: 2%;
            right: 5%;
          }
          &:nth-of-type(3) {
            width: 120px;
            top: 67%;
            right: 10%;
          }
          &:nth-of-type(4) {
            width: 100px;
            top: 80%;
            left: 2%;
          }
          &:nth-of-type(5) {
            width: 70px;
            top: 97%;
            left: 50%;
          }
        }
      }
    }
  }
  @include breakpoint($max: 500) {
    .banner-middle {
      h2 {
        font-size: 30px;
        line-height: 36px;
      }
      a {
        font-size: 12px;
      }
      &__second-part {
        width: 90%;
      }
    }
  }
</style>
