<template>
  <div class="landing">
    <HeaderLanding />
    <BannerTopGJ />
    <Announcing />
    <Prices />
    <Discover />
    <JoinUs />
    <Footer />
  </div>
</template>

<script>
import HeaderLanding from "@/components/GameJam/HeaderLanding.vue"
import BannerTopGJ from "@/components/GameJam/BannerTopGJ.vue"
import Announcing from "@/components/GameJam/Announcing.vue"
import Prices from "@/components/GameJam/Prices.vue"
import Discover from "@/components/GameJam/Discover.vue"
import JoinUs from "@/components/GameJam/JoinUs.vue"
import Footer from "@/components/GameJam/Footer.vue"

export default {
  name: 'GameJam',
  components: {
    HeaderLanding,
    BannerTopGJ,
    Announcing,
    Prices,
    Discover,
    JoinUs,
    Footer
  }
}
</script>

<style lang="scss" scoped>
  .landing {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
</style>
