{
  "meta": {
    "Home": {
      "title": "The Sandbox - The Walking Dead",
      "description": ""
    },
    "favicon": "/favicon.ico"
  },
  "bannerTop": {
    "title": "The Walking DEAD",
    "subtitle1": "invades",
    "subtitle2": "the Metaverse.",
    "paragraph1": "The Walking Dead brings a truly",
    "paragraph3": "new in-game experience inside The Sandbox.",
    "paragraph2": "Have fun with the Game Maker toolkit and bring The Walking Dead world to life in your own way, or enjoy the experience in other player's LANDs."
  },
  "bannerTopGJ": {
    "title": "The Walking DEAD",
    "subtitle1": "Game Jam",
    "subtitle2": "1/11/2021 - 14/11/2021",
    "button": "Start to create!"
  },
  "announcing": {
    "title": "Annoucing,",
    "subtitle": "THE WALKING DEAD Game Jam"
  },
  "joinUs": {
    "title": "Join our community",
    "paragraph": "Be part of our amazing community and stay tuned for the news.",
    "button": "Join our Discord"
  },
  "prices": {
    "title": "PRIZES",
    "paragraph1": "The prize will be",
    "paragraph2": "100,000+ SAND",
    "paragraph3": "broken down the following way:",
    "paragraph4": "Each winner also gets",
    "paragraph5": "one 1x1 LAND.",
    "paragraph6": "Each participant not in the top 10 will get 100 SAND.",
    "rank1": "1st place",
    "rank2": "2nd place",
    "rank3": "3rd place",
    "rank4": "4th to\n10th place",
    "price1": "40,000 SAND",
    "price2": "20,000 SAND",
    "price3": "10,000 SAND",
    "price4": "5,000 SAND\neach"
  },
  "discover": {
    "title1": "How to enter",
    "title2": "Get started",
    "list1" : {
      "paragraph1": "Your game entry must be developed entirely inside the",
      "paragraph2": "Game Maker software.",
      "paragraph3": "Your games should only be distributed through The Sandbox ecosystem (through The Game Maker gallery).",
      "paragraph4": "User-Generated Content (UGC) used inside experiences can include The Walking Dead NFTs, but they don’t have to.",
      "paragraph5": "The Game Jam will run for two weeks. Then there will be a one week period of judging, before the results are announced."
    },
    "list2" : {
      "paragraph1": "Download",
      "paragraph2": "The Game Maker",
      "paragraph3": "for free",
      "paragraph4": "Check out",
      "paragraph5": "The Game Maker Academy",
      "paragraph6": "to learn how to Build and Share your experiences.",
      "paragraph7": "In",
      "paragraph8": "The Sandbox Knowledge Base",
      "paragraph9": "you’ll find The Sandbox’s official documentation and help resource.",
      "paragraph10": "Join our",
      "paragraph11": "The Sandbox Discord Community",
      "paragraph12": " to talk to fellow creators!"
    },
    "button1": "Get Game Maker for free",
    "button2": "Create a account",
    "title3": "Discover the sandbox",
    "paragraph1": "The Sandbox is a virtual world where players can play, build, own, and monetize their virtual experiences. ",
    "paragraph2": "We empower artists, creators, and players to build the platform they always envisioned, providing the means to unleash your creativity.",
    "title4": "Ultimate the walking dead",
    "subtitle1": "Pack giveaway",
    "title5": "Prizes",
    "list3" : {
      "paragraph1": "The Walking Dead Deluxe Comic Book Issue #7,",
      "paragraph2": "signed by creator Robert Kirkman.",
      "paragraph3": "A set of The Walking Dead assets",
      "paragraph4": "to build your own The Walking Dead experience within The Sandbox, including Glenn, Negan, Rick, RV, Church, Windmill & Walkers.",
      "paragraph5": "A 1x1 LAND located in The Walking Dead’s neighbourhood",
      "paragraph6": "of The Sandbox’s metaverse."
    }
  },
  "login": {
    "button": "login or create an account",
    "descriptionRegular1": "Sign up, we don't bite, and get for free the exclusive",
    "descriptionBold": "The Walking Dead survivor backpack",
    "descriptionRegular2": "once the game is available."
  },
  "bannerMiddle": {
    "title1": "Play, create,",
    "title2": "own and govern",
    "subtitle1": "a virtual world made by players",
    "paragraph1": "Play experiences both single-player and multi-player. Create and animate assets with Voxedit 3D. Build unique game experiences at The Sandbox Metaverse with the Game Maker tool. Buy and Sell assets at the Marketplace to enhance your experience.",
    "paragraph6": "In The Sandbox, the only limit is your imagination.",
    "button1": "Start crafting your game >",
    "button1A": "Discover the map >",
    "title3": "Explore and Discover",
    "subtitle2": "the universe of The Walking Dead like never before.",
    "subtitle3": "Imagine",
    "subtitle4": "and create",
    "subtitle3A": "The Walking Dead LANDs",
    "subtitle5": "Don't wait to use them in your LAND.",
    "paragraph2": "your own world with 20+ unique in-game assets from The Walking Dead. Including Walkers, Survivors and a lot more.",
    "paragraph2A": "Own virtual real estate in the world of The Walking Dead. Build your own experiences with exclusive The Walking Dead assets.",
    "paragraph3": "Find characters and decorative elements from the comic book universe, including the Walkers, Michonne, and Lucille.",
    "paragraph5": "Discover the iconic assets"
  },
  "partners": {
    "title1": "Brands & IPs already",
    "title2": "in The Sandbox Metaverse."
  },
  "footer": {
    "title": "Follow us.",
    "text": "Based on the comic book The Walking Dead by Robert Kirkman, Tony Moore and Charlie Adlard. The Walking Dead is a registered or otherwise protected trademark owned by Robert Kirkman, LLC. Skybound is a registered or otherwise protected trademark owned by Skybound, LLC. Uses with permission.",
    "socialIcons": [
      {
        "name": "discord",
        "url": "https://discordapp.com/invite/vAe4zvY "
      },
      {
        "name": "telegram",
        "url": "https://t.me/sandboxgame"
      },
      {
        "name": "medium",
        "url": "https://medium.com/sandbox-game"
      },
      {
        "name": "twitter",
        "url": "https://twitter.com/TheSandboxGame"
      },
      {
        "name": "instagram",
        "url": "https://www.instagram.com/thesandboxgame/"
      },
      {
        "name": "facebook",
        "url": "https://www.facebook.com/TheSandboxGame/"
      },
      {
        "name": "youtube",
        "url": "https://www.youtube.com/user/TheSandboxGame"
      },
      {
        "name": "twitch",
        "url": "https://www.twitch.tv/thesandboxgame"
      },
      {
        "name": "reddit",
        "url": "https://www.reddit.com/r/TheSandboxGaming/"
      }
    ]
  }
}
