<template>
  <div class="banner-middle">
    <div class="banner-middle__content">
      <div class="banner-middle__content__images">
        <img src="@/assets/img/GameJam/JoinUs.png" alt="Join Us">
      </div>
      <div class="banner-middle__content__intro">
        <h2>{{ $t("joinUs.title") }}</h2>
        <p>{{ $t("joinUs.paragraph") }}</p>
        <a
                :href="`https://community.sandbox.game/${$i18n.locale}/`"
                target="_blank">{{ $t("joinUs.button") }}</a>
      </div>
      <div class="banner-middle__content__images">
        <img src="@/assets/img/GameJam/Walker2.png" alt="walker">
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "JoinUs"
}
</script>

<style lang="scss" scoped>
  .banner-middle {
    background-color: $gray2;
    @include flex(column, center, center);
    z-index: 2;
    position: relative;
    h2{
      font-weight: 1000;
      font-size: 33px;
      text-transform: uppercase;
      margin: 0;
    }
    p {
      font-size: 18px;
      font-weight: 600;
      line-height: 25px;
    }
    a {
      display: inline-block;
      background-color: $red-2;
      z-index: 1;
      color: white;
      border: none;
      font-weight: bold;
      text-transform: uppercase;
      padding: 15px 62px;
      border-radius: 60px;
      font-size: 19px;
      cursor: pointer;
      text-decoration: none;
      transition: 200ms;
      &:hover {
        background-color: white;
        color: $gray2;
      }
    }
    &__content {
      z-index: 1;
      width: 100%;
      max-width: 1335px;
      @include flex(row, center, space-between);
      &__intro {
        width: calc(50% - 220px);
        p {
          line-height: 23px;
          font-size: 15px;
          margin-bottom: 30px;
        }
      }
      &__images {
        &:first-of-type {
          margin-bottom: -27px ;
        }
        &:last-of-type>img {
          position: absolute;
          top: 0;
          pointer-events: none;
          width: 220px;
          margin-top: 120px;
          margin-right: 0;
        }
      }
    }
  }
  @include breakpoint($max: 768) {
    .banner-middle {
      &__content {
        &__images, p {
          display:none;
        }
        &__intro {
          width: 100%;
        }
      }
    }
  }
  @include breakpoint($max: 500) {
    .banner-middle {
      h2 {
        font-size: 30px;
        line-height: 36px;
      }
      a {
        font-size: 12px;
      }
    }
  }
</style>
