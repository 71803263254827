{
  "meta": {
    "Home": {
      "title": "The Sandbox - The Walking Dead",
      "description": ""
    },
    "favicon": "/favicon.ico"
  },
  "bannerTop": {
    "title": "The Walking DEAD",
    "subtitle1": "正式入侵",
    "subtitle2": "元宇宙！",
    "paragraph1": "行尸走肉即将在 The Sandbox 中带来真正意义上的全新游戏体验。",
    "paragraph3": "",
    "paragraph2": "尽情通过 Game Maker 游戏制作工具，用你自己的方式为这个世界注入活力，或者直接在其他玩家的土地上享受游戏体验。"
  },
  "login": {
    "button": "登录或创建一个账户",
    "descriptionRegular1": "快来注册吧，我们不咬人哦，一旦游戏上线，你就有机会免费获得行尸走肉的独家幸存者背包哦。",
    "descriptionBold": "",
    "descriptionRegular2": ""
  },
  "bannerMiddle": {
    "title1": "玩，创造，",
    "title2": "拥有和管理",
    "subtitle1": "这是一个由玩家创造的虚拟世界",
    "paragraph1": "该游戏有四个主要组成部分，你可以在线体验和其他玩家的创作之旅。我们的Voxedit 3D 体素艺术动画软件构建了游戏资产，而 Game Maker 工具让你在 The Sandbox 元宇宙中创造独特的游戏体验。市场也提供了一个可供购买和销售资产的地方。",
    "paragraph6": "在 The Sandbox 中，想象力是你唯一的天花板。",
    "button1": "开始制作你的游戏 >",
    "button1A": "Discover the map >",
    "title3": "探索与发现",
    "subtitle2": "行尸走肉元宇宙，前所未有的体验。",
    "subtitle3": "想象",
    "subtitle4": "并创造",
    "subtitle3A": "The Walking Dead LANDs",
    "subtitle5": "即刻在你的土地上使用它们吧！",
    "paragraph2": "你的20+款独一无二行尸走肉游戏资产，包括行屍、幸存者等等。",
    "paragraph2A": "Own virtual real estate in the world of The Walking Dead. Build your own experiences with exclusive The Walking Dead assets.",
    "paragraph3": "瞧一瞧这些所有来自原版漫画的人物和装饰元素，包括行屍、米琼恩和露西尔。",
    "paragraph5": "瞧一瞧这些标志性资产"
  },
  "partners": {
    "title1": "那些已经落户",
    "title2": "The Sandbox 的品牌和IP们！"
  },
  "footer": {
    "title": "关注我们",
    "text": "根据 Robert Kirkman, Tony Moore and Charlie Adlard 的漫画书《行尸走肉》。其注册和其他商标保护是由 Robert Kirkman, LLC 所有。Skybound 是由 Skybound, LLC 注册并拥有其商标保护权。以上品牌均经许可使用。",
    "socialIcons": [
      {
        "name": "discord",
        "url": "https://discordapp.com/invite/vAe4zvY "
      },
      {
        "name": "telegram",
        "url": "https://t.me/sandboxgame"
      },
      {
        "name": "medium",
        "url": "https://medium.com/sandbox-game"
      },
      {
        "name": "twitter",
        "url": "https://twitter.com/TheSandboxGame"
      },
      {
        "name": "instagram",
        "url": "https://www.instagram.com/thesandboxgame/"
      },
      {
        "name": "facebook",
        "url": "https://www.facebook.com/TheSandboxGame/"
      },
      {
        "name": "youtube",
        "url": "https://www.youtube.com/user/TheSandboxGame"
      },
      {
        "name": "twitch",
        "url": "https://www.twitch.tv/thesandboxgame"
      },
      {
        "name": "reddit",
        "url": "https://www.reddit.com/r/TheSandboxGaming/"
      }
    ]
  }
}
