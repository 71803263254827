<template>
  <ModalVideo
          :link-video="linkVideo"
          :show-modal="showModal"
          @close-modal="closeModal"
  />

  <div class="banner-middle">

    <div class="banner-middle__first_part">
      <div class="banner-middle__first_part__column">
        <h2>{{ $t("discover.title1") }}</h2>
        <div class="banner-middle__first_part__column__images">
          <img src="@/assets/img/GameJam/HowToEnter.png" :alt="$t('discover.title1')">
        </div>
        <ul>
          <li>{{ $t('discover.list1.paragraph1') }} <span>{{ $t('discover.list1.paragraph2') }}</span></li>
          <li>{{ $t('discover.list1.paragraph3') }}</li>
          <li>{{ $t('discover.list1.paragraph4') }}</li>
          <li>{{ $t('discover.list1.paragraph5') }}</li>
        </ul>
      </div>
      <div class="banner-middle__first_part__column">
        <h2>{{ $t("discover.title2") }}</h2>
        <div class="banner-middle__first_part__column__images">
          <img src="@/assets/img/GameJam/GetStarted.png" :alt="$t('discover.title2')">
        </div>
        <ul>
          <li>{{ $t('discover.list2.paragraph1') }} <span>{{ $t('discover.list2.paragraph2') }}</span> {{ $t('discover.list2.paragraph3') }}</li>
          <li>{{ $t('discover.list2.paragraph4') }} <span>{{ $t('discover.list2.paragraph5') }}</span> {{ $t('discover.list2.paragraph6') }}</li>
          <li>{{ $t('discover.list2.paragraph7') }} <span>{{ $t('discover.list2.paragraph8') }}</span> {{ $t('discover.list2.paragraph9') }}</li>
          <li>{{ $t('discover.list2.paragraph10') }} <span>{{ $t('discover.list2.paragraph11') }}</span> {{ $t('discover.list2.paragraph12') }}</li>
        </ul>
        <a :href="`https://gamemaker.sandbox.game/#/${$i18n.locale}/`"
           target="_blank">
          {{ $t("discover.button1") }}
        </a>
      </div>
    </div>

    <div class="banner-middle__image">
      <img src="@/assets/img/GameJam/blood.png" :alt="$t('discover.title2')">
    </div>

    <div class="banner-middle__second_part">
      <h2>{{ $t("discover.title3") }}</h2>
      <div class="banner-middle__second_part__video">
        <img src="@/assets/img/GameJam/Discover.png" :alt="$t('discover.title3')">
        <img
                src="@/assets/svg/playVideo.svg"
                alt="play the video"
                class="play-video"
                @click="openVideo('https://www.youtube.com/embed/0Jquhzr4d4k')"
        >
      </div>
      <p>{{ $t("discover.paragraph1") }}</p>
      <p>{{ $t("discover.paragraph2") }}</p>
      <a :href="`https://gamemaker.sandbox.game/#/${$i18n.locale}/`"
         target="_blank">
        {{ $t("discover.button1") }}
      </a>
    </div>

    <div class="banner-middle__third_part">
      <h2>{{ $t("discover.title4") }}</h2>
      <h3>{{ $t("discover.subtitle1") }}</h3>
      <div class="banner-middle__second_part__image">
        <img src="@/assets/img/GameJam/BlogBanner.png" :alt="$t('discover.title3')">
      </div>
    </div>

    <div class="banner-middle__fourth_part">
      <div class="banner-middle__fourth_part__images">
        <img src="@/assets/img/GameJam/GenericSurvivor.png" :alt="$t('discover.title5')">
        <img src="@/assets/img/GameJam/Walker1.png" :alt="$t('discover.title5')">
      </div>
      <h2>{{ $t("discover.title5") }}</h2>
      <ul>
        <li>{{ $t('discover.list3.paragraph1') }} <span>{{ $t('discover.list3.paragraph2') }}</span></li>
        <li><span>{{ $t('discover.list3.paragraph3') }}</span> {{ $t('discover.list3.paragraph4') }}</li>
        <li><span>{{ $t('discover.list3.paragraph5') }}</span> {{ $t('discover.list3.paragraph5') }}</li>
      </ul>
      <a :href="`https://gamemaker.sandbox.game/#/${$i18n.locale}/`"
         target="_blank">
        {{ $t("discover.button2") }}
      </a>
    </div>
  </div>
</template>

<script>
import ModalVideo from "@/components/ModalVideo"

export default {
  name: "Discover",
  components: {
    ModalVideo
  },
  data() {
    return {
      showModal: false,
      linkVideo: ""
    }
  },
  methods: {
    openVideo(linkVideo) {
      this.linkVideo = linkVideo;
      this.showModal = true;
    },
    track() {
      var axel = Math.random() + "";
      var a = axel * 10000000000000;
      const iframe = document.createElement('iframe');
      iframe.src = 'https://10802094.fls.doubleclick.net/activityi;src=10802094;type=invmedia;cat=2021-000;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=' + a + '?';
      iframe.style.display = "none";
      iframe.style.width = "1px";
      iframe.style.height = "1px";
      document.body.appendChild(iframe);
    },
    closeModal() {
      this.showModal = false;
    }
  }
}
</script>

<style lang="scss" scoped>
  .banner-middle {
    background-color: $grey-12;
    @include flex(column, center, center);
    padding: 100px 0;
    position: relative;
    h2, h3 {
      font-size: 50px;
      font-weight: 1000;
      line-height: 50px;
      margin: 0;
      text-transform: uppercase;
    }
    h3 {
      color: $red-2;
    }
    a {
      margin-top: 20px;
      display: inline-block;
      background-color: $red-2;
      z-index: 1;
      color: white;
      border: none;
      font-weight: bold;
      text-transform: uppercase;
      padding: 15px 62px;
      border-radius: 60px;
      font-size: 19px;
      cursor: pointer;
      text-decoration: none;
      transition: 200ms;
      &:hover {
        background-color: white;
        color: $gray2;
      }
    }
    &__first_part {
      margin-top: 120px;
      margin-bottom: 120px;
      z-index: 1;
      width: 100%;
      max-width: 1335px;
      @include flex(row, flex-start, center);
      &__column {
        text-align: left;
        width: 50%;
        &:first-of-type {
          margin-right: 50px;
        }
        &:last-of-type {
          margin-left: 50px;
        }
        &__images {
          position: relative;

          img {
            margin: 20px 0;
          }
        }
        ul {
          padding-left: 25px ;
        }
        li {
          margin-bottom: 50px;
          font-size: 28px;
          font-weight: 800;
          span:first-of-type {
            color: $red-2;
          }
        }
      }

    }

    &__image {
      position: relative;
      width: 100%;
      img {
        position: absolute;
        bottom: -200px;
        right: -150px;
      }
    }

    &__second_part {
      margin-top: 180px;
      margin-bottom: 120px;
      z-index: 1;
      width: 100%;
      max-width: 1335px;
      @include flex(column, center, center);

      h2{
        margin-bottom: 25px;
      }

      p{
        width: calc(56.5%);
        font-size: 28px;
        font-weight: 600;
      }
      &__video {
        width: calc(55.5%);
        position: relative;

        img {
          width: 100%;
        }

        .play-video {
          width: 60px;
          height: 60px;
          position: absolute;
          top: calc(50% - 30px);
          left: calc(50% - 30px);
          cursor: pointer;
        }
      }
    }

    &__third_part {
      margin-top: 180px;
      margin-bottom: 120px;
      z-index: 1;
      width: 100%;
      max-width: 1335px;
      @include flex(column, center, center);

      h2{
        margin-bottom: 25px;
      }

      p{
        width: calc(56.5%);
        font-size: 28px;
        font-weight: 600;
      }
      &__video {
        width: calc(55.5%);
        position: relative;

        img {
          width: 100%;
        }

        .play-video {
          width: 60px;
          height: 60px;
          position: absolute;
          top: calc(50% - 30px);
          left: calc(50% - 30px);
          cursor: pointer;
        }
      }
    }

    &__fourth_part {
      margin-top: 180px;
      margin-bottom: 120px;
      z-index: 1;
      width: 100%;
      max-width: 1335px;
      position: relative;
      @include flex(column, center, center);

       &__images {
         position: absolute;
         width: 100%;
         @include flex(row, center, space-between);
         img{
         }

       }
      ul {
        width: calc(56.5%);
        padding-left: 25px ;
      }
      li {
        margin-bottom: 50px;
        font-size: 28px;
        font-weight: 600;

        span:first-of-type {
          font-weight: 800;
        }
      }
    }
  }
  @include breakpoint($max: 1500) {
    .banner-middle {
      &__second_part {
        min-width: 900px;
        &__video {
          width: 100%;
          margin-top: 40px;
          max-width: 500px;
        }
      }
    }
  }
  @include breakpoint($max: 1100) {
    .banner-middle {
      &__first_part {
        @include flex(column, center, center);
      }
    }
  }
  @include breakpoint($max: 500) {
    .banner-middle {
      h2 {
        font-size: 30px;
        line-height: 36px;
      }
    }
  }
</style>
