<template>
  <div
    class="banner-top"
    :style="{ 'background-image': `url(${getImageBg()})` }"
  >
    <img class="snow" src="@/assets/img/snow.png" alt="snow">
    <div class="banner-top__infos">
      <img src="@/assets/img/title.png" :alt="$t('bannerTop.title')">
      <h3 :class="[
        'banner-top__infos__title',
        { 
          'banner-top__infos__title--margin-top': 
          $i18n.locale === 'jp' ||
          $i18n.locale === 'kr'
        }
        ]"
      >
        {{ $t("bannerTop.subtitle1") }}
      </h3>
      <h3>{{ $t("bannerTop.subtitle2") }}</h3>
      <p :style="{ 'background-image': `url(${getImageBgText()})` }">
        {{ $t("bannerTop.paragraph1") }}<br>
        {{ $t("bannerTop.paragraph3") }}
      </p>
      <p>{{ $t("bannerTop.paragraph2") }}</p>
      <div class="banner-top__infos__cubes">
        <img src="@/assets/img/cubes/cube7.png" alt="cube">
        <img src="@/assets/img/cubes/cube6.png" alt="cube">
      </div>
    </div>
    <div class="banner-top__images">
      <div class="banner-top__images--left">
        <img src="@/assets/img/walker6.png" alt="walker">
        <img src="@/assets/img/walker1.png" alt="walker">
      </div>
      <div class="banner-top__images--right">
        <img src="@/assets/img/character2.png" alt="walker">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BannerTop",
  methods: {
    getImageBgText() {
      return require('../assets/img/border.png');
    },
    getImageBg() {
      return require('../assets/img/bgBanner.png');
    }
  }
}
</script>

<style lang="scss" scoped>
  .banner-top {
    background: $gray1;
    background-size: cover;
    background-position: start;
    padding-top: 100px;
    margin-top: -80px;
    position: relative;
    z-index: 1;
    @include flex(row, center, center);
    .snow {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      object-fit: contain;
      mix-blend-mode: color-dodge;
      opacity: 0.4;
      z-index: 5;
      pointer-events: none;
    }
    &__infos {
      max-width: 500px;
      text-align: center;
      margin-bottom: 50px;
      font-size: 16px;
      position: relative;
      img {
        width: 400px;
        object-fit: contain;
      }
      h3 {
        font-size: 40px;
        margin: 0;
        line-height: 37px;
        text-transform: uppercase;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: black;
        font-weight: 1000;
      }
      p {
        margin: 30px 0px;
        line-height: 25px;
        &:first-of-type {
          @include flex(row, center, center);
          padding: 0px 35px;
          padding-top: 15px;
          color: $red;
          font-weight: 1000;
          background-position: center;
          background-size: cover;
          height: 98px;
        }
        &:last-of-type {
          font-size: 14px;
        }
      }
      &__title {
        &--margin-top {
          margin-top: 25px !important;
        }
      }
      &__cubes {
        position: absolute;
        top: 220px;
        left: 0;
        width: 100%;
        @include flex(row, center, space-between);
        img {
          &:first-of-type {
            width: 40px;
            margin-top: 150px;
            margin-left: -60px;
          }
          &:last-of-type {
            width: 100px;
          }
        }
      }
    }
    &__images {
      position: absolute;
      @include flex(row, center, space-between);
      width: 100%;
      top: 20px;
      left: 0;
      &--left {
        @include flex(row, baseline, space-between);
        position: absolute;
        left: 120px;
        top: 230px;
        z-index: 2;
        img {
          &:first-of-type {
            width: 180px;
          }
          &:last-of-type {
            width: 250px;
          }
        }
      }
      &--right {
        position: absolute;
        right: 100px;
        top: 40px;
        z-index: 2;
        img {
          width: 340px;
        }
      }
    }
  }
  @include breakpoint($max: 1530) {
    .banner-top {
      &__images {
        &--left {
          left: 80px;
          top: 320px;
          img {
            &:first-of-type {
              width: 140px;
            }
            &:last-of-type {
              width: 200px;
            }
          }
        }
        &--right {
          right: 50px;
          top: 150px;
          img {
            width: 280px;
          }
        }
      }
    }
  }
  @include breakpoint($max: 1280) {
    .banner-top {
      @include flex(column, center, center);
      &__infos {
        margin-bottom: 20px;
      }
      &__images {
        position: relative;
        margin-bottom: 50px;
        @include flex(row, center, center);
        top: 0;
        &--left {
          position: relative;
          left: auto;
          top: 0px;
          img {
            &:first-of-type {
              width: 130px;
            }
            &:last-of-type {
              width: 180px;
            }
          }
        }
        &--right {
          position: relative;
          right: auto;
          top: 0px;
          img {
            width: 200px;
            margin-left: 100px;
          }
        }
      }
    }
  }
  @include breakpoint($max: 750) {
    .banner-top {
      &__infos {
        max-width: 400px;
        font-size: 15px;
        img {
          width: 300px;
        }
        h3 {
          font-size: 22px;
          -webkit-text-stroke-width: 1px;
          line-height: 28px;
        }
        p {
          margin: 20px 10px;
          &:first-of-type {
            height: 75px;
            font-size: 14px;
            line-height: 18px;
            padding: 0px 20px;
            padding-top: 14px;
          }
          &:last-of-type {
            font-size: 13px;
          }
        }
        &__cubes {
          top: 160px;
          img {
            &:first-of-type {
              width: 30px;
              margin-left: -25px;
            }
            &:last-of-type {
              width: 80px;
            }
          }
        }
      }
      &__images {
        &--left {
          img {
            &:first-of-type {
              width: 70px;
            }
            &:last-of-type {
              width: 100px;
            }
          }
        }
        &--right {
          img {
            width: 100px;
            margin-left: 40px;
          }
        }
      }
    }
  }
  @include breakpoint($max: 465) {
    .banner-top {
      &__infos {
        max-width: 400px;
        &__title {
          &--margin-top {
            margin-top: 10px !important;
          }
        }
        p {
          margin: 20px 10px;
          &:first-of-type {
            background-image: none !important;
            font-size: 15px;
            line-height: 20px;
            padding: 0px 0px;
          }
          &:last-of-type {
            font-size: 13px;
          }
        }
        &__cubes {
          display: none;
        }
      }
    }
  }
</style>