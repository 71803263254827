<template>
  <div class="banner-top">
    <div class="banner-top__infos">
      <img src="@/assets/img/GameJam/title.png" :alt="$t('bannerTop.title')">
      <h3 :class="[
        'banner-top__infos__title',
        {
          'banner-top__infos__title--margin-top':
          $i18n.locale === 'jp' ||
          $i18n.locale === 'kr'
        }
        ]"
      >
        {{ $t("bannerTopGJ.subtitle1") }}
      </h3>
      <h3>
        {{ $t("bannerTopGJ.subtitle2") }}
      </h3>
      <a
              :href="`https://gamemaker.sandbox.game/#/${$i18n.locale}/`"
              target="_blank"
      >
        {{ $t("bannerTopGJ.button") }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "BannerTopGJ",
  methods: {
    getImageBg() {
      return require('../../assets/img/GameJam/bgBanner.png');
    }
  }
}
</script>

<style lang="scss" scoped>
  .banner-top {
    background: $gray1;
    background-size: cover;
    background-position-x: center;
    background-position-y: bottom;
    padding-top: 100px;
    margin-top: -50px;
    position: relative;
    height: 1080px;
    z-index: 1;
    background-image: url("../../assets/img/GameJam/bgBanner.png");
    @include flex(row, center, center);
    &__infos {
      max-width: 800px;
      text-align: center;
      margin-bottom: 50px;
      font-size: 16px;
      position: relative;
      img {
        margin-top: -100px;
        width: 800px;
        object-fit: contain;
      }
      h3 {
        margin: 0;
        font-size: 43px;
        line-height: 38px;
        text-transform: uppercase;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: black;
        font-weight: 1000;

        &:first-of-type {
          margin-top: -120px;
          font-size: 90px;
          line-height: 83px;
        }
      }
      &__title {
        &--margin-top {
          margin-top: 25px !important;
        }
      }
    }
  }
  a {
    margin-top: 20px;
    display: inline-block;
    background-color: $red-2;
    z-index: 1;
    color: white;
    border: none;
    font-weight: bold;
    text-transform: uppercase;
    padding: 15px 62px;
    border-radius: 60px;
    font-size: 19px;
    cursor: pointer;
    text-decoration: none;
    transition: 200ms;
    &:hover {
      background-color: white;
      color: $gray2;
    }
  }
  @include breakpoint($max: 1280) {
    .banner-top {
      background-image: url("../../assets/img/GameJam/bgBanner800.png");
      @include flex(column, center, center);
      &__infos {
        margin-bottom: 20px;
        @include flex(column, space-between, space-between);
      }
      &__images {
        position: relative;
        margin-bottom: 50px;
        @include flex(row, center, center);
        top: 0;

        &--left {
          position: relative;
          left: auto;
          top: 0px;
          img {
            &:first-of-type {
              width: 130px;
            }
            &:last-of-type {
              width: 180px;
            }
          }
        }
        &--right {
          position: relative;
          right: auto;
          top: 0px;
          img {
            width: 200px;
            margin-left: 100px;
          }
        }
      }
    }
  }
  @include breakpoint($max: 750) {
    .banner-top {
      &__infos {
        max-width: 400px;
        font-size: 15px;
        img {
          width: 300px;
        }
        h3 {
          font-size: 22px;
          -webkit-text-stroke-width: 1px;
          line-height: 28px;
        }
      }
    }
  }
  @include breakpoint($max: 465) {
    .banner-top {
      background-image: url("../../assets/img/GameJam/bgBanner360.png");
      &__infos {
        max-width: 400px;
        &__title {
          &--margin-top {
            margin-top: 10px !important;
          }
        }
      }
    }
  }
</style>
