import { createRouter, createWebHistory } from 'vue-router'
import Landing from '../views/Landing.vue'
import GameJam from '../views/GameJam.vue'
import i18n from '../i18n'

const routes = [
  {
    path: '/',
    redirect: { name: 'Landing', params: {
      lang: "en"
    }}
  },
  {
    path: '/:lang',
    name: 'Landing',
    component: Landing
  },
  {
    name: 'GameJam',
    path: '/:lang/game-jam',
    component: GameJam,
  }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory("/#/"),
  routes
})

router.beforeEach((to, from, next) => {
  // let language = to.params.lang
  // let url = to.fullPath.split('/')

  // if (url.length < 3) {
  //     if (!Object.keys(i18n.global.messages).includes(language)) {
  //         return next(i18n.global.locale + '/' + url[1])
  //     } else {
  //         return next(language + '/' + url[1])
  //     }
  // } else {
  //     if (!Object.keys(i18n.global.messages).includes(language)) {
  //         return next('en/' + url[2])
  //     }
  //     if (!language) {
  //         language = 'en'
  //     }

  //     i18n.global.locale = language
  //     next()
  // }
  let language = to.params.lang
  i18n.global.locale = language
  next()
})

export default router
