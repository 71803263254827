<template>
    <section
        id="anchor-partners"
        class="partners">
        <div class="partners__title">
            <h2>{{ $t("partners.title1") }}</h2>
            <h2>{{ $t("partners.title2") }}</h2>
        </div>
        <ul class="press-logos main-partners">
            <li
                v-for="(item, i) in bigPartners"
                :key="i"
                class="press-logos-item__big">
                <a :href="item.url" target="_blank">
                    <img
                        :src="require('../assets/img/partners/' + item.img)"
                        class="press_img1" :id="'img' + i" />
                </a>
            </li>
        </ul>
    </section>
</template>

<script>
export default {
    name: 'Partners',
    data () {
        return {
            bigPartners: [
                {
                    url: 'https://www.animocabrands.com/',
                    img: 'Logo_Animoca_Grey.png',
                },
                {
                    url: 'https://www.binance.com/en/trade/SAND_USDT',
                    img: 'binance.png',
                },
                {
                    url: 'https://coinmarketcap.com/',
                    img: 'coinmarketcap-logo.png',
                },
                {
                    url: 'https://boredapeyachtclub.com/',
                    img: 'Logo_BoredApes.png',
                },
                {
                    url: 'https://www.cyberkongz.com/',
                    img: 'cyberkongz.png',
                }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
    .partners {
        background-color: $grey-15;
        color: white;
        padding: 70px 20%;
        padding-bottom: 90px;
        z-index: 0;
        @include flex(column, center, center);
        #img4 {
            max-width: 140px;
        }
        #img2 {
            max-width: 120px;
        }
        &__title {
                margin: 20px 0px;
                margin-top: 30px;
            h2 {
                font-weight: 600;
                font-size: 30px;
                font-weight: bold;
                font-weight: bold;
                margin: 0;
                text-align: center
            }
        }
        ul {
            @include flex(row, center, center);
            flex-wrap: wrap;
            list-style: none;
            padding: 0;
        }
    }
    @media (max-width: 1430px) {
      .partners {
          padding: 90px 15%;
      }
    }
    @media (max-width: 1120px) {
        .partners {
            padding: 80px 10%;
        }
    }
</style>
